import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + "transactions";
let pay_once_url = url + "/pay_once";
let export_url = url + "/export";
let merchant_pay_url = url + "/merchant_pay"

class PaymentsAPI {
  payOnceWithCard = (data, auth_token, user_pin, callback) => {
    return apiUtils.post(pay_once_url, data, auth_token, user_pin, callback);
  };
  getList = (auth_token, user_pin, query_string) => {
    var final_url = url
    if (typeof query_string !== "undefined"){
      final_url += query_string
    }
    return apiUtils.get(final_url, auth_token, user_pin);
  };
  exportToCSV = (auth_token) => {
    return apiUtils.post(export_url, undefined, auth_token);
  }
  takePayment = (data, auth_token) => {
    return apiUtils.post(merchant_pay_url, data, auth_token);
  };
  unschedule = (data, auth_token, user_pin) => {
    let unscheduleUrl = url + '/unschedule'
    return apiUtils.post(unscheduleUrl, data, auth_token, user_pin)
  }
  getStatus = (transaction_id, auth_token) => {
    let statusUrl = url + `/${transaction_id}/get_status`;
    return apiUtils.get(statusUrl, auth_token)
  };
  cancelTransaction = (data, auth_token) => {
    let cancelUrl = url + '/cancel';
    return apiUtils.post(cancelUrl, data, auth_token);
  };
  makeSplitPayment = (data, auth_token) => {
    let splitPayment = url + '/split_payment';
    return apiUtils.post(splitPayment, data, auth_token);
  }
}


export default new PaymentsAPI();
