import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import styled from 'styled-components'

const COLORS = ['#22599e', '#e49c3e', '#e06937', '#bcd8c2', '#699fd9'];

const StyledPiechart = styled.div`
  && {
    .recharts-wrapper {
      position: static;
      display: flex;
      flex-direction: column;
    }
    .recharts-legend-wrapper{
      position: static !important;
      display: flex;
      flex-direction: column;
      width: auto !important;
      
      ul {
        display: flex;
        flex-direction: column;
      }
    }
  }
`


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
 	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy  + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    	{`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class CardTypePieChart extends React.Component{
  constructor(props) {
    super(props);
    this.state = {}
  }

  render () {
    const { data } = this.props;
    let formatted = []
    if (data) {
      formatted = data.filter((report) => report.value > 0 )
    }

  	return (
      <StyledPiechart>
        <PieChart width={230} height={230} onMouseEnter={this.onPieEnter}>
          <Pie
            dataKey="value"
            data={formatted}
            cx={105}
            cy={100}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
          >
            {
              formatted.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key={index}/>)
            }
          </Pie>
          <Legend/>
        </PieChart>
      </StyledPiechart>
    );
  }
};

export default CardTypePieChart;
