import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import history from '../../../history';

//Components
import Table from '../../../components/tables/Table';

//API & Utils
import paymentAPI from '../../../api/payments/paymentAPIs';
import globalUtils from '../../../utils/globalUtils';

const PageWrapper = styled.div`
  min-width: 600px;
`;

const columns = [
  {
   name: "invoice",
   label: "Invoice",
   options: {
    filter: false,
    sort: false,
    display: false
   }
 },
 {
  name: "user",
  label: "Name",
  options: {
   filter: false,
   sort: true
  }
 },
 {
  name: "amount_paid",
  label: "Amount",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => {
     if (tableMeta.rowData) {
       return (
         value
       )
     }
   },
  }
 },
 {
  name: "paid_date",
  label: "Date",
  options: {
   filter: false,
   sort: true,
   sortDirection: 'desc',
   customBodyRender: (value, tableMeta, updateValue) => {
     if (tableMeta.rowData) {
       return (
         globalUtils.formatDate(value)
       )
     }
   },
  }
 },
 {
  name: "status",
  label: "Status",
  options: {
   filter: true,
   sort: true,
   setCellProps: (row) => {
      return {
        style: {textTransform: 'capitalize'}
    };
  }
  }
 },
 {
  name: "visible_method",
  label: "Payment Method",
  options: {
   filter: false,
   sort: true,
  },
},
 {
  name: "invoice_description",
  label: "Invoice Description",
  options: {
   filter: false,
   sort: true,
   display: false,
   customBodyRender: value => !value ?  "" : value.length > 65 ? value.substr(0, 65) + "..." : value
  },
},
{
  name: "merchant_name",
  label: "Merchant Name",
  options: {
   filter: false,
   sort: true,
   display: false,
   customBodyRender: value => !value ?  "" : value.length > 65 ? value.substr(0, 65) + "..." : value
  },
  
}
];

class PaymentsIndexListWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
    data: []
    };

    globalUtils.loginRedirect(props);
    this.getData();
  }

  rerouteToUpload = () => {
    this.props.history.push('/admin/payments/upload');
  }

  options = {
    filterType: 'checkbox',
    responsive: 'stacked',
    selectableRows: false,
    onRowClick: (rowData, rowState) => {
      let invoice = rowData[0]
      history.push({
        pathname: '/admin/invoices/detail/' + invoice,
        state: 'test'
      })
    },
    // onRowsDelete: (rowsDeleted) => {
    //   let data = this.state.data;
    //   let rows = rowsDeleted.data;
    //   rows.forEach((val, row) => {
    //     data.forEach(async (v,r) => {
    //       if(val.dataIndex === r) {
    //         await paymentAPI.cancelTransaction(v, this.props.auth_token);
    //       }
    //     });
    //   });
    //   window.location.reload();
    //   return false;
    // }
  };

  getData = async () => {
    let formatted_data = [];
    let data = await paymentAPI.getList(this.props.auth_token)

    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }
    if (data && data.length > 0) {
      formatted_data = data.map(function(row){
        row["date"] = globalUtils.formatDate(row["date"])
        row["amount_paid"] = globalUtils.convertToDollars(row["amount_paid"]);
        return row
      })
    this.setState({
      data: formatted_data, loaded: true
    })
    } else {
    this.setState({
      loaded: true
    })
    }
  }


  render() {
    return (
      <>
        <PageWrapper>
          <Table
            title="Transactions"
            data={this.state.data}
            tableClassName="table"
            showFilter={true}
            showSettings={false}
            filterPlaceholderText="Search"
            upload
            rerouteToUpload={this.rerouteToUpload}
            loaded={this.state.loaded}
            columns={columns}
            options={this.options}>
          </Table>
        </PageWrapper>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};
export default connect(
  mapStateToProps
)(PaymentsIndexListWrapper)
